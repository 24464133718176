.popularity{
  min-width: 100px;
  margin-top: 5px;
}

.popularity::before,
.popularity::after{
  display: table;
  content: ' ';
}

.popularity::after{
  clear: both;
}

.popularity.extra__popularity{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.popularity__desc{
  color: #8c838a;
  font-size: 90%;
  padding-top: .3rem;
  display: block;
  clear: both;
}

.popularity__item{
  float: left;
  width: 18.4%;
  height: 4px;
  background-color: lightgrey;
  margin-left: 2%;
}

.popularity__item:first-of-type{
  margin-left: 0;
}

.popularity--level1 .popularity__item:first-of-type,
.popularity--level2 .popularity__item:first-of-type,
.popularity--level3 .popularity__item:first-of-type,
.popularity--level4 .popularity__item:first-of-type,
.popularity--level5 .popularity__item:first-of-type,
.popularity--level6 .popularity__item:first-of-type{
  background: #53c9c8;
}

.popularity--level2 .popularity__item:nth-of-type(2),
.popularity--level3 .popularity__item:nth-of-type(2),
.popularity--level4 .popularity__item:nth-of-type(2),
.popularity--level5 .popularity__item:nth-of-type(2),
.popularity--level6 .popularity__item:nth-of-type(2){
  background: -webkit-linear-gradient(left, #53c9c8, #f9bc48);
  background: linear-gradient(to right, #53c9c8, #f9bc48);
}

.popularity--level3 .popularity__item:nth-of-type(3),
.popularity--level4 .popularity__item:nth-of-type(3),
.popularity--level5 .popularity__item:nth-of-type(3),
.popularity--level6 .popularity__item:nth-of-type(3){
  background: #f9bc48;
}

.popularity--level4 .popularity__item:nth-of-type(4),
.popularity--level5 .popularity__item:nth-of-type(4),
.popularity--level6 .popularity__item:nth-of-type(4){
  background: -webkit-linear-gradient(left, #f9bc48 50%, #f85a39);
  background: linear-gradient(to right, #f9bc48 50%, #f85a39);
}

.popularity--level5 .popularity__item:nth-of-type(5),
.popularity--level6 .popularity__item:nth-of-type(5){
  background: #f85a39;
}
