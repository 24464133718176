.question-header{
  margin-top: 3em;
  margin-bottom: 1.5em;

  .heading1{
    text-align: center;
    font-size: 4em;
  }

  .question-hashtag{
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }
}

.question-header-data{
  .question-header-data-group{
    margin-bottom: 1em;
    font-size: 1.2em;

    .question-eader-data-group-title{
      color: #857d84;
    }
  }

  .question-header-data-group:last-child{
    margin-bottom: 0;
  }
}

.question-vote-cabin{
  margin-bottom: 3em;
  display: flex;
  align-items: center;
  justify-content: center;

  .vote-button-caption{
    font-size: 1.5em;
    font-weight: bold;
    color: white;
  }

  .small-buttons div{
    margin-top: 1em;
  }
}

.consultations-card{
  .button.expanded.button--sc.success{
    color: white;
  }
}
