@import "stylesheets/decidim/initiatives/popularity_item";
@import "stylesheets/decidim/initiatives/statistics";
@import "stylesheets/decidim/initiatives/admin";

.ql-formats{
  .ql-video{
    margin: 0;
  }
}

.initiative-status{
  float: left;
  margin-right: .5rem;
}

.supports-list{
  .author{
    margin-right: 15px;

    .author__name{
      color: #599aa6;
    }
  }
}

.initiative-authors{
  padding-left: 0 !important;

  .publish-date{
    display: inline-block;
    margin-left: -10px;
  }

  .author{
    margin-right: 15px;
  }

  .author__avatar--small{
    margin-left: 0 !important;
  }

  .author__name{
    margin-left: 25px;
  }
}

.lowercase{
  text-transform: lowercase !important;
}

.legal_text{
  font-size: 8pt;
}

.progress__bar--horizontal{
  &:last-of-type{
    margin-bottom: 1.5rem;
  }
}

fieldset{
  padding: .5em;
  margin-bottom: 1em;
}
