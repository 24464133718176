.card--process__column .consultations-card__image{
  min-height: 0;

  &::after{
    display: none;
  }

  > div{
    height: 100%;
    display: flex;
    align-items: center;
  }

  .button{
    margin-bottom: 0;
  }
}
