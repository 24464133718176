.consultations-intro{
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: $margin-s;

  @include breakpoint(medium){
    margin-bottom: $margin-sm;
  }
}
