.consultations-home-banner{
  .consultations-header__main{
    &::after{
      display: none;
    }
  }

  .consultations-header__container{
    min-height: 8rem;
  }

  p:last-child{
    margin-bottom: 0;
  }
}
